// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../application/images', true)
// const imagePath = (name) => images(name, true)

import '@stripe/stripe-js'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import "../application/stylesheets/main.sass"

var bulmaCarousel = require("bulma-carousel")
global.bulmaCarousel = bulmaCarousel
window.bulmaCarousel = bulmaCarousel

// var bulmaCarousel = require("bulma-carousel")
// global.bulmaCarousel = bulmaCarousel
// window.bulmaCarousel = bulmaCarousel

require("trix")
require("@rails/actiontext")

var Trix = require("trix")
Trix.config.attachments.preview.caption = { name: false, size: false }
Trix.config.blockAttributes.default.tagName = "p"
require("@rails/actiontext")
